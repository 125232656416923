import React,{Component} from 'react'
import './home.css';
import ResearchThemes from '../components/researchThemes';
import LabIntro from '../components/labIntro';
import News from '../components/news';
import {Slideshow} from '../components/slideshow';

export default class Home extends Component {
  render(){
    return(<>
      <div className="desktop-view" >
        <div className="horizontal-padding max-width-home">
          <div style={{display:"flex",flexDirection:"column"}}>
            <div className="left-section">
              <Slideshow/>

              <div style={{height:"20px"}}/>
              <LabIntro/>
	      
              <div style={{height:"20px"}}/>
	            <ResearchThemes/>
            
              <News/>
            
            </div>
          
            
          </div>
        </div>
      </div>

    <div className="mobile-view">
      <div className="horizontal-padding">
        <Slideshow/>
	      <LabIntro/>
        <ResearchThemes/>
        <News/>
      </div>
    </div>
    
    </>)
  }
}
